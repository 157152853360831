import {Alert, Stack} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import {config} from "../../../config";
import dayjs from "dayjs";
import MessagesCard from "./MessageCard";
import {useHistory} from "react-router-dom";
import getCookie from "../../../functions/getCookie";

export default function Messages({active}) {
    const [startMessage, setStartMessage] = useState({})
    const [termsOfUseMessage, setTermsOfUseMessage] = useState({})
    const [afterBuyMessage, setAfterBuyMessage] = useState({})
    const history = useHistory();

    useEffect(()=>{
        fetch(`${config.host}/lilshop/messages/getAll`, {
            method: 'Post', // или 'PUT'
            headers: {
                'accept': 'text/plain',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'token': localStorage.getItem('token')
            }
        }).then((response) => {
            if (response.status === 200) {
                response.json().then((data) => {
                    setStartMessage(data.startMessage)
                    setTermsOfUseMessage(data.termsOfUseMessage)
                    setAfterBuyMessage(data.afterBuyMessage)
                })
            }
            else if(response.status === 401){
                history.push('/lilshop/authentication', {from: "/lilshop/constructor"})
            }
        })
    },[])
    return <Stack width={'100%'} display={active?'flex':'none'} marginTop={'20px'}>
        <Stack flexWrap={'wrap'} flexDirection={'row'} justifyContent={'space-between'}>
            <MessagesCard title={'Приветственное сообщение'} message={startMessage}/>
            <MessagesCard title={'Соглашение при старте'} message={termsOfUseMessage}/>
            <MessagesCard title={'Сообщение при получении товара'} message={afterBuyMessage}/>
        </Stack>
    </Stack>
}
