import Header from "../Header";
import Menu from "../Menu";
import { Stack } from "@mui/material";
import ModalDelete from "./Categories/ModalDelete";
import ModalCreateEdit from "./Categories/ModalCreateEdit";
import Button from "@mui/material/Button";
import Table from "../Table/Table";
import React, { useState } from "react";
import PageSelector from "./Constructor/PageSelector";
import Messages from "./Constructor/Messages";
import Buttons from "./Constructor/Buttons";
import Reload from "./Constructor/Reload";



export default function Constructor() {
    const [page, setPage] = useState('messages')
    return <Stack direction={'row'}>
        <Menu active={'Constructor'} />
        <Stack direction={'column'} width={'100%'}>
            <Header />
            <Stack className="main-style-page">
                <Stack className="inner-in-main-page ">
                    <Stack style={{ width: '98%', marginLeft: '1%' }} direction={'row'} justifyContent={'space-between'}
                        alignItems={'baseline'}>
                        <Stack style={{ fontSize: 20, marginBottom: 20, marginLeft: 20 }}>Конструктор</Stack>
                    </Stack>
                    <PageSelector page={page} setPage={setPage} />
                    <div className="split" style={{marginBottom:'20px'}}></div>
                    <Messages active={page === 'messages'} />
                    <Buttons active={page === 'buttons'} />
                    <Reload active={page === 'reload'} />
                </Stack>
            </Stack>
        </Stack>

    </Stack>
}
