import {Alert, Snackbar, Stack} from "@mui/material";
import * as React from "react";
import Button from "@mui/material/Button";
import {useEffect, useState} from "react";
import {config} from "../../../config";
import ModalCreateEdit from "../Constructor/ModalCreateEdit";
import getCookie from "../../../functions/getCookie";
import {useHistory} from "react-router-dom";

const addCategoryButton = {
    color: 'white',
    backgroundColor: 'rgb(45, 3, 59)',
    ':hover': {
        backgroundColor: 'rgba(45,3,59,0.75)',
    },
    textTransform: 'none',
    fontSize: 20
}



export default function Reload({active}) {


    const proxyHandler = () => {
        fetch('https://reload.liladminpanel.space/reloadProxy').then((response) => {
            if (response.status === 200) {
                alert('ok')
            }
            else {
                alert('error')
            }
        })
    }

    const smsHandler = () => {
        fetch('https://reload.liladminpanel.space/reloadSms').then((response) => {
            if (response.status === 200) {
                alert('ok')
            }
            else {
                alert('error')
            }
        })
    }

    const shopHandler = () => {
        fetch('https://reload.liladminpanel.space/reloadShop').then((response) => {
            if (response.status === 200) {
                alert('ok')
            }
            else {
                alert('error')
            }
        })
    }



    return <Stack width={'100%'} sx={{alignItems: 'center', justifyContent:'center'}} display={active?'flex':'none'} marginTop={'20px'}>
        <Stack sx={{backgroundColor:'white', width: '40%'}}  paddingTop={'10px'}>
            <Stack style={{fontSize: 25, marginBottom: 20, width: '100%', textAlign:'center'}}>Перезагрузить бота</Stack>

            <Button  sx={{fontWeight:'bold', fontSize:'20px'}} onClick={() => proxyHandler()}>Proxy</Button>
            <Button  sx={{fontWeight:'bold', fontSize:'20px'}} onClick={() => smsHandler()}>SMS</Button>
            <Button  sx={{fontWeight:'bold', fontSize:'20px'}} onClick={() => shopHandler()}>Shop</Button>
        </Stack>
    </Stack>
}
