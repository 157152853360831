import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { MenuItem, Select, Stack, TextField } from "@mui/material";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react'
import { config, quillModules } from "../../../config";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import 'dayjs/locale/ru';
import getCookie from "../../../functions/getCookie";
import { useHistory } from "react-router-dom";
Quill.register('modules/imageResize', ImageResize)

const modalStyle = {
    position: 'absolute',
    width: '60%',
    maxWidth: '500px',
    borderRadius: '15px',
    backgroundColor: 'white',
    overflow: 'auto',
    maxHeight: '70%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    paddingBottom: '20px',
    maskImage: 'linear-gradient(to right, transparent, 0px, white calc(100% - 0px), transparent)'
}

const textFieldStyle = {
    minWidth: 300,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '10px'
}

const selectStyle = {
    minWidth: 300,
    width: '60%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '10px',
    height: '40px',
}

const saveButtonStyle = {
    color: 'white',
    backgroundColor: '#2c8cd5',
    ':hover': {
        backgroundColor: '#2c8cd5',
    },
    textTransform: 'none',
    fontSize: 20,
    minWidth: 300,
    width: '60%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '10px',
    marginBottom: '10px',
    height: '40px',
}

const quillStyle = {
    minWidth: 300,
    width: '60%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '10px',
    marginBottom: '10px'
}

const imageStyle = {
    maxWidth: '60%',
    maxHeight: 600,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '10px',
}

export default function ModalCreateEdit({ open, setOpen, id, flag, setFlag }) {
    const [name, setName] = useState('')
    const [content, setContent] = useState('')
    const [image, setImage] = useState('https://img3.gelbooru.com//samples/0c/9e/sample_0c9ef2ca007c272e825fbafe2a8d89d4.jpg')
    const [time, setTime] = useState()
    const [buttonName, setButtonName] = useState('')
    const [buttonLink, setButtonLink] = useState('')
    const history = useHistory();

    useEffect(() => {
        if (id) {
            fetch(`${config.host}/lilshop/mailings/getOne`, {
                method: 'Post', // или 'PUT'
                headers: {
                    'accept': 'text/plain',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'token': localStorage.getItem('token')
                },
                body: JSON.stringify({
                    'id': id,
                })
            }).then((response) => {
                if (response.status === 200) {
                    response.json().then((data) => {
                        setName(data.name)
                        setContent(data.text)
                        setImage(data.image || '')
                        setTime(dayjs(data.time))
                        setButtonName(data.nameInline)
                        setButtonLink(data.linkInline)
                    })
                }
                else if (response.status === 401) {
                    history.push('/lilshop/authentication', { from: "/lilshop/mailing" })
                }
            })
        }
        else {
            setName('')
            setTime(dayjs(new Date()))
            setContent('')
            setImage('')
            setButtonName('')
            setButtonLink('')
        }
    }, [id])


    const handleSave = () => {
        if (id) {
            fetch(`${config.host}/lilshop/mailings/updateOne`, {
                method: 'Post', // или 'PUT'
                headers: {
                    'accept': 'text/plain',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'token': localStorage.getItem('token')
                },
                body: JSON.stringify({
                    'id': id,
                    'name': name,
                    'text': content,
                    'time': time,
                    'image': image,
                    'nameInline': buttonName,
                    'linkInline': buttonLink
                })
            }).then((response) => {
                if (response.status === 200) {
                    setOpen(false)
                    setFlag(flag + 1)
                }
                else if (response.status === 401) {
                    history.push('/lilshop/authentication', { from: "/lilshop/mailing" })
                }
            })
        }
        else {
            fetch(`${config.host}/lilshop/mailings/createOne`, {
                method: 'Post', // или 'PUT'
                headers: {
                    'accept': 'text/plain',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'token': localStorage.getItem('token')
                },
                body: JSON.stringify({
                    'name': name,
                    'text': content,
                    'time': time,
                    'image': image,
                    'nameInline': buttonName,
                    'linkInline': buttonLink
                })
            }).then((response) => {
                if (response.status === 200) {
                    setOpen(false)
                    setFlag(flag + 1)
                }
                else if (response.status === 401) {
                    history.push('/lilshop/authentication', { from: "/lilshop/mailing" })
                }
            })
        }
    }


    return <div>
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={modalStyle}>
                {/*{parents}*/}
                <Stack
                    style={{ marginBottom: 10, fontSize: 25, backgroundColor: '#2c8cd5', padding: 10, color: 'white' }} alignItems={'center'}>{id ? 'Изменить рассылку' : 'Добавить рассылку'}</Stack>
                <Stack alignItems={'center'}>
                    <Stack direction={'column'} sx={{ width: '100%' }} justifyContent={'space-around'}>
                        <Stack>
                            <TextField value={name} size="small" sx={textFieldStyle} label={'Название'} onChange={(e) => setName(e.target.value)} />
                            <ReactQuill
                                style={quillStyle}
                                modules={quillModules}
                                theme="snow"
                                value={content}
                                onChange={setContent} />
                            {/*<TextField multiline  rows={10} value={content} sx={textFieldStyle} onChange={(e)=>setContent(e.target.value)}/>*/}

                            <TextField size="small" value={image} sx={textFieldStyle} label={'Изображение'} onChange={(e) => setImage(e.target.value)} />
                            <img style={imageStyle} src={image} />
                        </Stack>
                        <Stack>
                            <LocalizationProvider sx={textFieldStyle} size="small" dateAdapter={AdapterDayjs} adapterLocale="ru">
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <DemoContainer components={['DateTimePicker']} sx={{ width: '60%' }}>
                                        <DateTimePicker
                                            label="Время отправки"
                                            value={time}
                                            onChange={(newValue) => setTime(newValue)}
                                        />
                                    </DemoContainer>
                                </div>
                            </LocalizationProvider>
                            <TextField size="small" value={buttonName} sx={textFieldStyle} label={'Название кнопки'} onChange={(e) => setButtonName(e.target.value)} />
                            <TextField size="small" value={buttonLink} sx={textFieldStyle} label={'Ссылка'} onChange={(e) => setButtonLink(e.target.value)} />
                        </Stack>
                    </Stack>
                    <Button sx={saveButtonStyle} onClick={handleSave}>Сохранить</Button>
                </Stack>
            </Box>
        </Modal>
    </div>
}
