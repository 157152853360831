import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Checkbox, FormControlLabel, MenuItem, Select, Stack, TextField } from "@mui/material";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react'
import { config, quillModules } from "../../../config";
import getCookie from "../../../functions/getCookie";
import { useHistory } from "react-router-dom";

Quill.register('modules/imageResize', ImageResize)

const modalStyle = {
    position: 'absolute',
    width: '60%',
    maxWidth: '500px',
    borderRadius: '15px',
    backgroundColor: 'white',
    overflow: 'auto',
    maxHeight: '70%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    paddingBottom: '20px',
    maskImage: 'linear-gradient(to right, transparent, 0px, white calc(100% - 0px), transparent)'
}

const textFieldStyle = {
    minWidth: 300,
    width: '60%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '10px'
}

const saveButtonStyle = {
    color: 'white',
    backgroundColor: '#2c8cd5',
    ':hover': {
        backgroundColor: '#2c8cd5',
    },
    textTransform: 'none',
    fontSize: 20,
    minWidth: 300,
    width: '60%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '10px',
    marginBottom: '10px',
    height: '40px',
}


export default function ModalAddMaterial({ open, setOpen, id, name, flag, setFlag }) {
    const [content, setContent] = useState('')
    const [endless, setEndless] = useState(false)
    const history = useHistory();

    useEffect(() => {
        setContent('')
        setEndless(false)
    }, [id])

    const handleSave = () => {
        fetch(`${config.host}/lilshop/goods/addMaterial`, {
            method: 'Post', // или 'PUT'
            headers: {
                'accept': 'text/plain',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'token': localStorage.getItem('token')
            },
            body: JSON.stringify({
                'good': id,
                'materials': content,
                'endless': endless
            })
        }).then((response) => {
            if (response.status === 200) {
                // response.json().then((data) => {
                //     setName(data.name)
                //     setActive(data.active)
                //     setContent(data.description)
                //     setImage(data.image)
                //     setParent(data.parent_id?data.parent_id:0)
                // })
                setOpen(false)
                setFlag(flag + 1)
            }
            else if (response.status === 401) {
                history.push('/lilshop/authentication', { from: "/lilshop/goods" })
            }
        })
    }


    return <div>
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={modalStyle}>
                <Stack
                    style={{ marginBottom: "10px", fontSize: 25, backgroundColor: '#2c8cd5', padding: 10, color: 'white' }}
                    alignItems={'center'}>Добавить материал
                </Stack>
                <Stack>
                    <Stack sx={textFieldStyle}>
                        {name}
                    </Stack>
                    <TextField multiline rows={10} value={content} sx={textFieldStyle} onChange={(e) => setContent(e.target.value)} />
                    <Stack>
                        <FormControlLabel sx={textFieldStyle} control={<Checkbox checked={endless} onChange={(e) => setEndless(!endless)} />} label="Бесконечное количество материала" />
                    </Stack>
                    <Button sx={saveButtonStyle} onClick={handleSave} >Сохранить</Button>
                </Stack>
            </Box>
        </Modal>
    </div>
}
