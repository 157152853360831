import {Alert, Checkbox, FormControlLabel, Snackbar, Stack, TextField} from "@mui/material";
import * as React from "react";
import Button from "@mui/material/Button";
import {config} from "../../../config";
import {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import getCookie from "../../../functions/getCookie";


const textFieldStyle = {
    minWidth: 300,
    width: '60%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '10px'
}

const saveButtonStyle = {
    color: 'white',
    backgroundColor: '#2c8cd5',
    ':hover': {
        backgroundColor: '#2c8cd5',
    },
    textTransform: 'none',
    fontSize: 20,
    minWidth: 300,
    width: '60%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '10px',
    marginBottom: '10px',
    height: '40px',
}

export default function Payok() {
    const [shop, setShop] = useState('')
    const [secretkey, setSecretkey] = useState('')
    const [active, setActive] = useState(true)
    const [open, setOpen] = useState(false)
    const history = useHistory();

    useEffect(() => {

        fetch(`${config.host}/lilshop/payments/getPayok`, {
            method: 'Post', // или 'PUT'
            headers: {
                'accept': 'text/plain',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'token': localStorage.getItem('token')
            }
        }).then((response) => {
            if (response.status === 200) {
                response.json().then((data) => {
                    setShop(data.shop)
                    setSecretkey(data.secret_key)
                    setActive(data.active)
                })

            }
            else if(response.status === 401){
                history.push('/lilshop/authentication', {from: "/lilshop/payments"})
            }
        })
    }, [])

    const saveHandler = () => {
        fetch(`${config.host}/lilshop/payments/updatePayok`, {
            method: 'Post', // или 'PUT'
            headers: {
                'accept': 'text/plain',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'token': localStorage.getItem('token')
            },
            body: JSON.stringify({
                'shop': shop,
                'secretkey': secretkey,
                'active': active,
            })
        }).then((response) => {
            if (response.status === 200) {
                setOpen(true)
            }
            else if(response.status === 401){
                history.push('/lilshop/authentication', {from: "/lilshop/payments"})
            }
        })
    }
    return <Stack sx={{backgroundColor: 'white'}} width={'30%'} minWidth={'350px'} marginBottom={'20px'}
                  minHeight={'300px'} height={'30%'}>
        <Stack alignItems={'center'}>
            <Stack style={{width: '98%', marginLeft: '1%'}} direction={'row'} justifyContent={'space-evenly'}
                   alignItems={'center'}>
                <Stack fontSize={20}>
                    Payok
                </Stack>
                <Stack>
                    <FormControlLabel minWidth={'300px'} width={'60%'}
                                      control={<Checkbox checked={active} onChange={() => setActive(!active)}/>}
                                      label="Включить"/>
                </Stack>
            </Stack>
            <TextField size="small" value={shop} sx={textFieldStyle} label={'Магазин'}
                       onChange={(e) => setShop(e.target.value)}/>
            <TextField size="small" value={secretkey} sx={textFieldStyle} label={'Секретный ключ'}
                       onChange={(e) => setSecretkey(e.target.value)}/>
            <Snackbar open={open} autoHideDuration={6000} onClose={() => setOpen(false)}>
                <Alert onClose={() => setOpen(false)} severity="success">
                    Сохранено!
                </Alert>
            </Snackbar>
            <Button sx={saveButtonStyle} onClick={saveHandler}>Сохранить</Button>
        </Stack>
    </Stack>
}
