import {Stack} from "@mui/material";
import * as React from "react";
import Button from "@mui/material/Button";
import {useEffect, useState} from "react";
import {config} from "../../../config";
import ModalCreateEdit from "../Constructor/ModalCreateEdit";
import getCookie from "../../../functions/getCookie";
import {useHistory} from "react-router-dom";

const addCategoryButton = {
    color: 'white',
    backgroundColor: '#2c8cd5',
    ':hover': {
        backgroundColor: '#2c8cd5',
    },
    textTransform: 'none',
    fontSize: 18,
    width: '50%',
    marginTop: '25px'
}

export default function Buttons({active}) {
    const [id, setId] = useState(0)
    const [open, setOpen] = useState(false)
    const [buttons, setButtons] = useState([])
    const [flag, setFlag] = useState(0)
    const history = useHistory();


    useEffect(()=>{
        fetch(`${config.host}/lilshop/buttons/getAll`, {
            method: 'Post', // или 'PUT'
            headers: {
                'accept': 'text/plain',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'token': localStorage.getItem('token')
            }
        }).then((response) => {
            if (response.status === 200) {
                response.json().then((data) => {
                    setButtons(data)
                })
            }
            else if(response.status === 401){
                history.push('/lilshop/authentication', {from: "/lilshop/constructor"})
            }
        })
    },[flag])

    return <Stack width={'100%'} style={{alignItems:'center', justifyContent:'center'}}  display={active?'flex':'none'} marginTop={'20px'}>
        <Stack sx={{backgroundColor:'white', width:'40%', alignItems:'center'}}  paddingTop={'10px'}>
            <Stack style={{width: '98%', marginLeft: '1%'}} direction={'row'} justifyContent={'space-between'}
                   alignItems={'baseline'}>
                <Stack style={{fontSize: 25, marginBottom: 20, width: '100%', textAlign:'center'}}>Кнопки</Stack>
            </Stack>

            <ModalCreateEdit open={open} setOpen={setOpen} id={id} flag={flag} setFlag={setFlag}/>
            <Stack flexWrap={'wrap'} flexDirection={'row'} justifyContent={'space-between'}>
                {
                    buttons.map((button, i) => (
                        <Button key={i} sx={{flex:'0 0 45%', color:button.active?'blue':'gray'}}
                                onClick={()=>{
                                    setId(button.id)
                                    setOpen(true)
                                }}>{button.name}</Button>
                    ))
                }
            </Stack>
            <Button sx={addCategoryButton} onClick={() => {
                    setId(0)
                    setOpen(true);}}>Добавить кнопки</Button>
        </Stack>
    </Stack>
}
