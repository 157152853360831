import React from "react";
import {Stack} from "@mui/material";
import { useLocation } from "react-router-dom";

const headerStyle = {
    backgroundColor:'rgb(255,255,255)',
    height:'9%',
    minHeight:'70px',
    maxHeight:'80px',
    width:'100%',
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center',
    flexDirection: 'row'
}

const headerTextStyle= {
    color:'black',
    fontSize:'2em',
    marginLeft:10,
}

const exitBtn = {
    backgroundColor: '#2c8cd5',
    color:'white',
    fontWeight:'bold',
    fontSize: '14px',
    borderRadius:5,
    padding:'5px 20px',
    marginRight: '15px',
    cursor: 'pointer',
    ':hover': {
        backgroundColor: '#3c5cd10',
    },
}


export default function Header() {

    const location = useLocation();

    const exit = () => {
        localStorage.setItem('token','');
        window.location.reload();
    }
    

    return <div style={headerStyle}>
         <div style={headerTextStyle}>LIL Shop Panel</div>
         <div style={exitBtn} onClick={exit}>Выход</div>
    </div>
}














