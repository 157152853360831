import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Checkbox, FormControlLabel, MenuItem, Select, Stack, TextField } from "@mui/material";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react'
import { config, quillModules } from "../../../config";
import fileDownload from "js-file-download";
import { useHistory } from "react-router-dom";
import getCookie from "../../../functions/getCookie";

Quill.register('modules/imageResize', ImageResize)

const modalStyle = {
    position: 'absolute',
    width: '60%',
    maxWidth: '500px',
    borderRadius: '15px',
    backgroundColor: 'white',
    overflow: 'auto',
    maxHeight: '70%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    paddingBottom: '20px',
    maskImage: 'linear-gradient(to right, transparent, 0px, white calc(100% - 0px), transparent)'

}

const textFieldStyle = {
    minWidth: 300,
    width: '60%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '10px',
}


const saveButtonStyle = {
    color: 'white',
    backgroundColor: '#2c8cd5',
    ':hover': {
        backgroundColor: '#2c8cd5',
    },
    textTransform: 'none',
    fontSize: 20,
    minWidth: 300,
    width: '60%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '10px',
    marginBottom: '10px',
    height: '40px',
}


export default function ModalUnloadingMaterial({ open, setOpen, id, name, flag, setFlag }) {
    const [count, setCount] = useState('')
    const [remove, setRemove] = useState(false)
    const history = useHistory();

    useEffect(() => {
        setCount('')
        setRemove(false)
    }, [id])

    const handleSave = () => {
        let limit = count === '' ? null : parseInt(count)
        fetch(`${config.host}/lilshop/goods/unloadingSome`, {
            method: 'Post', // или 'PUT'
            headers: {
                'accept': 'text/plain',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'token': localStorage.getItem('token')
            },
            body: JSON.stringify({
                'id': id,
                'limit': limit,
                'remove': remove
            }),
        }).then((response) => {
            if (response.status === 200) {
                response.text().then((data) => {
                    const element = document.createElement("a");
                    const file = new Blob([data], { type: 'text/plain' });
                    element.href = URL.createObjectURL(file);
                    element.download = `${name}.txt`;
                    document.body.appendChild(element); // Required for this to work in FireFox
                    element.click();
                })
                setOpen(false)
                setFlag(flag + 1)
            }
            else if (response.status === 401) {
                history.push('/lilshop/authentication', { from: "/lilshop/goods" })
            }
        })
    }


    return <div>
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={modalStyle}>
                <Stack
                    style={{ marginBottom: "10px", fontSize: 25, backgroundColor: '#2c8cd5', padding: 10, color: 'white' }}
                    alignItems={'center'}>Выгрузить материал
                </Stack>
                <Stack>
                    <Stack sx={textFieldStyle}>
                        Товар: {name}
                    </Stack>
                    <TextField size="small" value={count} sx={textFieldStyle} onChange={(e) => setCount(e.target.value)} />
                    <Stack>
                        <FormControlLabel sx={textFieldStyle} control={<Checkbox checked={remove} onChange={() => setRemove(!remove)} />} label="Удалить выгруженные материалы" />
                    </Stack>
                    <Button sx={saveButtonStyle} onClick={handleSave} >Выгрузить</Button>
                </Stack>
            </Box>
        </Modal>
    </div>
}
