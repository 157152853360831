import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { MenuItem, Select, Stack, TextField } from "@mui/material";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react'
import { config, quillModules } from "../../../config";
import getCookie from "../../../functions/getCookie";
import { useHistory } from "react-router-dom";
Quill.register('modules/imageResize', ImageResize)

const modalStyle = {
    position: 'absolute',
    width: '60%',
    maxWidth: '500px',
    borderRadius: '15px',
    backgroundColor: 'white',
    overflow: 'auto',
    maxHeight: '70%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    paddingBottom: '20px',
    maskImage: 'linear-gradient(to right, transparent, 0px, white calc(100% - 0px), transparent)'
}

const textFieldStyle = {
    minWidth: 300,
    width: '60%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '20px'
}

const selectStyle = {
    minWidth: 300,
    width: '60%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '10px',
    height: '40px',
}

const saveButtonStyle = {
    color: 'white',
    backgroundColor: '#2c8cd5',
    ':hover': {
        backgroundColor: '#2c8cd5',
    },
    textTransform: 'none',
    fontSize: 20,
    minWidth: 300,
    width: '60%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '10px',
    marginBottom: '10px',
    height: '40px',
}

const quillStyle = {
    minWidth: 300,
    width: '60%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '10px',
    zIndex: 1000
}

const imageStyle = {
    maxWidth: '60%',
    maxHeight: 600,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '10px',
}

export default function ModalCreateEdit({ open, setOpen, id, flag, setFlag }) {
    const [parent, setParent] = useState(0)
    const [allCategories, setAllCategories] = useState([])
    const [name, setName] = useState('')
    const [content, setContent] = useState('')
    const [active, setActive] = useState(true)
    const [image, setImage] = useState('https://img3.gelbooru.com//samples/0c/9e/sample_0c9ef2ca007c272e825fbafe2a8d89d4.jpg')
    const history = useHistory();

    useEffect(() => {
        if (id) {
            fetch(`${config.host}/lilshop/categories/getOne`, {
                method: 'Post', // или 'PUT'
                headers: {
                    'accept': 'text/plain',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'token': localStorage.getItem('token')
                },
                body: JSON.stringify({
                    'id': id,
                })
            }).then((response) => {
                if (response.status === 200) {
                    response.json().then((data) => {
                        setName(data.name)
                        setActive(data.active)
                        setContent(data.description)
                        setImage(data.image || '')
                        setParent(data.parent_id ? data.parent_id : 0)
                    })
                }
                else if (response.status === 401) {
                    history.push('/lilshop/authentication', { from: "/lilshop/categories" })
                }
            })
        }
        else {
            setName('')
            setActive(true)
            setContent('')
            setImage('')
            setParent(0)
        }

        fetch(`${config.host}/lilshop/categories/getAll`, {
            method: 'Post', // или 'PUT'
            headers: {
                'accept': 'text/plain',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'token': localStorage.getItem('token')
            },
            body: JSON.stringify({
                'id': id,
            })
        }).then((response) => {
            if (response.status === 200) {
                response.json().then((data) => {
                    setAllCategories(data.rows)
                })
            }
            else if (response.status === 401) {
                history.push('/lilshop/authentication', { from: "/lilshop/categories" })
            }
        })
    }, [id])


    const handleSave = () => {
        if (id) {
            fetch(`${config.host}/lilshop/categories/updateOne`, {
                method: 'Post', // или 'PUT'
                headers: {
                    'accept': 'text/plain',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'token': localStorage.getItem('token')
                },
                body: JSON.stringify({
                    'id': id,
                    'name': name,
                    'description': content,
                    'active': active,
                    'image': image,
                    'parent_id': parent
                })
            }).then((response) => {
                if (response.status === 200) {
                    // response.json().then((data) => {
                    //     setName(data.name)
                    //     setActive(data.active)
                    //     setContent(data.description)
                    //     setImage(data.image)
                    //     setParent(data.parent_id?data.parent_id:0)
                    // })
                    setOpen(false)
                    setFlag(flag + 1)
                }
                else if (response.status === 401) {
                    history.push('/lilshop/authentication', { from: "/lilshop/categories" })
                }
            })
        }
        else {
            fetch(`${config.host}/lilshop/categories/createOne`, {
                method: 'Post', // или 'PUT'
                headers: {
                    'accept': 'text/plain',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'token': localStorage.getItem('token')
                },
                body: JSON.stringify({
                    'name': name,
                    'description': content,
                    'active': active,
                    'image': image,
                    'parent_id': parent
                })
            }).then((response) => {
                if (response.status === 200) {
                    // response.json().then((data) => {
                    //     setName(data.name)
                    //     setActive(data.active)
                    //     setContent(data.description)
                    //     setImage(data.image)
                    //     setParent(data.parent_id?data.parent_id:0)
                    // })
                    setOpen(false)
                    setFlag(flag + 1)
                }
                else if (response.status === 401) {
                    history.push('/lilshop/authentication', { from: "/lilshop/categories" })
                }
            })
        }
    }


    return <div>
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={modalStyle}>
                {/*{parents}*/}
                <Stack
                    style={{ marginBottom: "10px", fontSize: 25, backgroundColor: '#2c8cd5', padding: 10, color: 'white' }} alignItems={'center'}>{id ? 'Изменить категорию' : 'Добавить категорию'}</Stack>
                <Stack>
                    <TextField value={name} size="small" sx={textFieldStyle} label={'Название'} onChange={(e) => setName(e.target.value)} />
                    <Select sx={selectStyle}
                        value={parent}
                        onChange={(e) => setParent(e.target.value)}
                    >
                        <MenuItem value={0}>
                            {'Главная'}
                        </MenuItem>
                        {allCategories.map((item) => (
                            <MenuItem value={item.id} key={item.id} >
                                {item.name}
                            </MenuItem>
                        ))}
                    </Select>
                    {/*<TextField multiline  rows={10} value={content} sx={textFieldStyle} onChange={(e)=>setContent(e.target.value)}/>*/}
                    <ReactQuill
                        style={quillStyle}
                        modules={quillModules}
                        theme="snow"
                        value={content}
                        onChange={setContent}

                    />
                    <Select sx={selectStyle}
                        value={active}
                        onChange={(e) => setActive(e.target.value)}
                    >
                        <MenuItem value={true}>
                            Видна
                        </MenuItem>
                        <MenuItem value={false} >
                            Скрыта
                        </MenuItem>
                    </Select>
                    <TextField size="small" value={image} sx={textFieldStyle} label={'Изображение'} onChange={(e) => setImage(e.target.value)} />
                    <img style={imageStyle} src={image} />
                    <Button sx={saveButtonStyle} onClick={handleSave}>Сохранить</Button>
                </Stack>
            </Box>
        </Modal>
    </div>
}
