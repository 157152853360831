import {Alert, Checkbox, FormControlLabel, Snackbar, Stack, TextField} from "@mui/material";
import * as React from "react";
import Button from "@mui/material/Button";
import {config} from "../../../config";
import {useEffect, useState} from "react";
import getCookie from "../../../functions/getCookie";
import {useHistory} from "react-router-dom";



const textFieldStyle = {
    minWidth: 300,
    width: '60%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '10px'
}

const saveButtonStyle = {
    color: 'white',
    backgroundColor: '#2c8cd5',
    ':hover': {
        backgroundColor: '#2c8cd5',
    },
    textTransform: 'none',
    fontSize: 20,
    minWidth: 300,
    width: '60%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '10px',
    marginBottom: '10px',
    height: '40px',
}



export default function Qiwi() {
    const [phone, setPhone] = useState('')
    const [token, setToken] = useState('')
    const [active, setActive] = useState(true)
    const [open, setOpen] = useState(false)
    const history = useHistory();

    useEffect(() => {

        fetch(`${config.host}/lilshop/payments/getQiwi`, {
            method: 'Post', // или 'PUT'
            headers: {
                'accept': 'text/plain',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'token': localStorage.getItem('token')
            }
        }).then((response) => {
            if (response.status === 200) {
                response.json().then((data) => {
                    setPhone(data.phone)
                    setToken(data.token)
                    setActive(data.active)
                })
            }
            else if(response.status === 401){
                history.push('/lilshop/authentication', {from: "/lilshop/payments"})
            }
        })
    }, [])

    const saveHandler = () => {
        fetch(`${config.host}/lilshop/payments/updateQiwi`, {
            method: 'Post', // или 'PUT'
            headers: {
                'accept': 'text/plain',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'token': localStorage.getItem('token')
            },
            body: JSON.stringify({
                'phone': phone,
                'token': token,
                'active': active,
            })
        }).then((response) => {
            if (response.status === 200) {
                setOpen(true)
            }
            else if(response.status === 401){
                history.push('/lilshop/authentication', {from: "/lilshop/payments"})
            }
        })
    }
    return <Stack sx={{backgroundColor: 'white'}} width={'30%'} minWidth={'350px'} marginBottom={'20px'}
                  minHeight={'300px'} height={'30%'}>
        <Stack alignItems={'center'}>
            <Stack style={{width: '98%', marginLeft: '1%'}} direction={'row'} justifyContent={'space-evenly'}
                   alignItems={'center'}>
                <Stack fontSize={20}>
                    Qiwi
                </Stack>
                <Stack>
                    <FormControlLabel minWidth={'300px'} width={'60%'}
                                      control={<Checkbox checked={active} onChange={() => setActive(!active)}/>}
                                      label="Включить"/>
                </Stack>
            </Stack>
            <TextField size="small" value={phone} sx={textFieldStyle} label={'Номер'}
                       onChange={(e) => setPhone(e.target.value)}/>
            <TextField size="small" value={token} sx={textFieldStyle} label={'API token'}
                       onChange={(e) => setToken(e.target.value)}/>
            <Snackbar open={open} autoHideDuration={6000} onClose={() => setOpen(false)}>
                <Alert onClose={() => setOpen(false)} severity="success">
                    Сохранено!
                </Alert>
            </Snackbar>
            <Button sx={saveButtonStyle} onClick={saveHandler}>Сохранить</Button>
        </Stack>
    </Stack>
}
