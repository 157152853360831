import React, { useEffect, useState } from "react";
import { FormControl, MenuItem, Select, Stack, TextField } from "@mui/material";
import TableTitle from "./TableTitle";
import TableLine from "./TableLine";
import TablePagesLine from "./TabelPagesLine";
import TableFilter from "./TableFilter";
import { config } from '../../config'   
import { DatePicker } from "@mui/x-date-pickers";
import DataPickerExemple from "../DataPickerExemple";

export default function Table({ setting, section }) {
    const [filtered, setFiltered] = useState([])
    const [query, setQuery] = useState("");
    const [draggedIndex, setDraggedIndex] = useState(null);


    useEffect(() => {
        const timeOutId = setTimeout(() => setting[1].search.set(query), 500);
        return () => clearTimeout(timeOutId);
    }, [query]);


    useEffect(() => {
        if (section == 'Goods' || section == 'Categories') {
            let idx = 0
            for (const id of setting[0][0].id) {
                setting[0][0].position[idx] = id;
                idx++;
            }
        }
        setFiltered(setting[0].map((item) => {
            return { title: item.title, filterOn: false }
        }))
    }, [setting[0][0].id])


    const startDragAndDrop = (index) => {
        setDraggedIndex(index);
    };

    const handleDrop = (index) => {
        if (draggedIndex === index) {
            return;
        }

        const draggedItem = setting[0][0].position[draggedIndex];
        const tmp = [...setting[0][0].position];
        tmp.splice(draggedIndex, 1);
        tmp.splice(index, 0, draggedItem);
        setting[0][0].position = tmp


        for (let i = 0; i < setting[0].length; i++) {
            const draggedItem = setting[0][i].data[draggedIndex];
            const newData = [...setting[0][i].data];
            newData.splice(draggedIndex, 1);
            newData.splice(index, 0, draggedItem);
            setting[0][i].data = newData
        }

        if (section == 'Goods') {
            fetch(`${config.host}/lilshop/goods/changePosition`, {
                method: 'Post',
                headers: {
                    'accept': 'text/plain',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'token': localStorage.getItem('token')
                },
                body: JSON.stringify(Object.assign({}, setting[0][0], { 'offset': (setting[1].page.get - 1) * setting[1].limit.get }))
            })
        } else if (section == 'Categories') {
            fetch(`${config.host}/lilshop/categories/changePosition`, {
                method: 'Post',
                headers: {
                    'accept': 'text/plain',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'token': localStorage.getItem('token')
                },
                body: JSON.stringify(Object.assign({}, setting[0][0], { 'offset': (setting[1].page.get - 1) * setting[1].limit.get }))
            })
        }

        setDraggedIndex(null);
    };


    return (
        <div style={{ overflowX: 'auto' }}>
            <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"baseline"}
                style={{ height: 50, marginTop: 20 }}
            >
                <FormControl>
                    <Select
                        sx={{ height: 35, fontSize: "14px" }}
                        value={setting[1].limit.get}
                        onChange={(e) => setting[1].limit.set(e.target.value)}
                    >
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                        <MenuItem value={250}>250</MenuItem>
                        <MenuItem value={500}>500</MenuItem>
                        <MenuItem value={1000}>1000</MenuItem>
                    </Select>
                </FormControl>
                <Stack direction={"row"}>
                    <TableFilter setting={setting[1].filter} />
                    <TextField className="controllers-input" placeholder="Поиск..." onChange={(e) => setQuery(e.target.value)} />
                    
                </Stack>
            </Stack>

            <Stack direction={"column"} style={{ marginLeft: 10, marginRight: 10 }}>
                <TableTitle data={setting[0]} sort={setting[1].sort} />
                
                {
                    section == 'Goods' || section == 'Categories' ?
                        setting[0][0].data.map((item, i) => (
                            <div
                                key={i}
                                draggable
                                onDragStart={() => startDragAndDrop(i)}
                                onDragOver={(e) => e.preventDefault()}
                                onDrop={() => handleDrop(i)}
                            >
                                <TableLine data={setting[0]} num={i} style={{ height: 30 }} />
                            </div>
                        ))
                        :
                        setting[0][0].data.map((item, i) => (
                            <div
                                key={i}
                            >
                                <TableLine data={setting[0]} num={i} style={{ height: 30 }} />
                            </div>
                        ))
                }

            </Stack>

            <Stack
                direction={"row"}
                justifyContent={"flex-end"}
                style={{ margin: 15 }}
            >
                <TablePagesLine
                    pages={setting[1].pages}
                    page={setting[1].page.get}
                    setPage={setting[1].page.set}
                />
            </Stack>
        </div>
    );
}
