import React, { useState } from "react";
import { Stack } from "@mui/material";
import { Link } from "react-router-dom";
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import StackedLineChartOutlinedIcon from '@mui/icons-material/StackedLineChartOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import SellIcon from '@mui/icons-material/Sell';

export default function Menu({ active, height }) {
    let menuStyleOpen = {
        width: '17%',
        minWidth: '230px',
        maxWidth: '230px',
        backgroundColor: 'rgb(44 140 213)',
        display: 'flex',
        alignItems: 'start',
        paddingTop: '15px',
        paddingBottom: '15px',
        paddingRight: '15px',
        paddingLeft: '30px'
        // height: document.documentElement.offsetHeight
    }
    let menuStyleClose = {
        width: '13%',
        minWidth: '100px',
        maxWidth: '100px',
        backgroundColor: 'rgb(44 140 213)',
        display: 'flex',
        alignItems: 'center',
        padding: '15px',
        // height: document.documentElement.offsetHeight
    }
    let activeItemStyle = {
        outline: 'none',
        textDecoration: 'none',
        color: 'rgb(5,232,255)',
        fontSize: 13,
        display: 'flex',
        alignItems: 'center',
        marginBottom: 17,
        fontWeight: 'medium',
        fontWeight: 600,
        width: '100%'
    }
    let inactiveItemStyle = {
        outline: 'none',
        textDecoration: 'none',
        color: 'white',
        fontSize: 13,
        display: 'flex',
        alignItems: 'center',
        marginBottom: 17,
        fontWeight: 600,
        width: '100%'
    }
    const iconStyle = {
        marginRight: '10px'
    }

    const [menu, setMenu] = useState(localStorage.getItem('openMenu') ? localStorage.getItem('openMenu') : 'true');
    
    const closeMenu = () => {
        setMenu('false');
        localStorage.setItem('openMenu', 'false');
    }

    const openMenu = () => {    
        setMenu('true')
        localStorage.setItem('openMenu', 'true');
    }


    if (menu == 'true') {
        console.log(1)
        return <Stack style={menuStyleOpen}>
                    <FavoriteBorderOutlinedIcon sx={{ height: '2em', width: '2em', marginLeft: 'auto', marginRight: 'auto', color: 'white', marginBottom: '1em' }} />
                    <Link to="/lilshop/analytics" style={active === 'Analytics' ? activeItemStyle : inactiveItemStyle}><StackedLineChartOutlinedIcon sx={iconStyle} /> АНАЛИТИИКА</Link>
                    <Link to="/lilshop/sells" style={active === 'Sells' ? activeItemStyle : inactiveItemStyle}><SellIcon sx={iconStyle} /> ПРОДАЖИ</Link>
                    <Link to="/lilshop/categories" style={active === 'Categories' ? activeItemStyle : inactiveItemStyle}><StorefrontOutlinedIcon sx={iconStyle} /> КАТЕГОРИИ</Link>
                    <Link to="/lilshop/goods" style={active === 'Goods' ? activeItemStyle : inactiveItemStyle}><ShoppingBagOutlinedIcon sx={iconStyle} /> ТОВАРЫ</Link>
                    <Link to="/lilshop/orders" style={active === 'Orders' ? activeItemStyle : inactiveItemStyle}><LocalMallOutlinedIcon sx={iconStyle} /> ЗАКАЗЫ</Link>
                    <Link to="/lilshop/Refills" style={active === 'Refills' ? activeItemStyle : inactiveItemStyle}><AttachMoneyIcon sx={iconStyle} /> ПОПОЛНЕНИЯ</Link>
                    <Link to="/lilshop/users" style={active === 'Users' ? activeItemStyle : inactiveItemStyle}><GroupOutlinedIcon sx={iconStyle} /> ПОЛЬЗОВАТЕЛИ</Link>
                    <Link to="/lilshop/mailing" style={active === 'Mailing' ? activeItemStyle : inactiveItemStyle}><MailOutlinedIcon sx={iconStyle} /> РАССЫЛКИ</Link>
                    <Link to="/lilshop/promotionallinks" style={active === 'Promotional links' ? activeItemStyle : inactiveItemStyle}><InsertLinkOutlinedIcon sx={iconStyle} /> РЕКЛАМНЫЕ ССЫЛКИ</Link>
                    <Link to="/lilshop/constructor" style={active === 'Constructor' ? activeItemStyle : inactiveItemStyle}><SettingsOutlinedIcon sx={iconStyle} /> КОНСТРУКТОР</Link>
                    <Link to="/lilshop/payments" style={active === 'Payments' ? activeItemStyle : inactiveItemStyle}><StoreOutlinedIcon sx={iconStyle} /> ОПЛАТА</Link>
                    <div style={{display:'flex', justifyContent:'center', width:'90%'}}>
                        <i className="far fa-arrow-alt-circle-left" onClick={closeMenu}></i>
                    </div>
                </Stack>
    } else {
       return <Stack style={menuStyleClose}>
                <FavoriteBorderOutlinedIcon sx={{ height: '2em', width: '2em', marginLeft: 'auto', marginRight: 'auto', color: 'white', marginBottom: '1em' }} />
                <Link to="/lilshop/analytics" style={active === 'Analytics' ? activeItemStyle : inactiveItemStyle}><div className="menu_close_icons"><StackedLineChartOutlinedIcon  /> <span>АНАЛИТИИКА</span> </div></Link>
                <Link to="/lilshop/sells" style={active === 'Sells' ? activeItemStyle : inactiveItemStyle}><div className="menu_close_icons"><SellIcon/> <span>ПРОДАЖИ</span> </div></Link>
                <Link to="/lilshop/categories" style={active === 'Categories' ? activeItemStyle : inactiveItemStyle}><div className="menu_close_icons"><StorefrontOutlinedIcon  /> <span>КАТЕГОРИИ</span> </div></Link>
                <Link to="/lilshop/goods" style={active === 'Goods' ? activeItemStyle : inactiveItemStyle}><div className="menu_close_icons"><ShoppingBagOutlinedIcon  /> <span>ТОВАРЫ</span> </div></Link>
                <Link to="/lilshop/orders" style={active === 'Orders' ? activeItemStyle : inactiveItemStyle}><div className="menu_close_icons"><LocalMallOutlinedIcon  /> <span>ЗАКАЗЫ</span> </div></Link>
                <Link to="/lilshop/Refills" style={active === 'Refills' ? activeItemStyle : inactiveItemStyle}><div className="menu_close_icons"><AttachMoneyIcon  /> <span>ПОПОЛНЕНИЯ</span> </div></Link>
                <Link to="/lilshop/users" style={active === 'Users' ? activeItemStyle : inactiveItemStyle}><div className="menu_close_icons"><GroupOutlinedIcon   /> <span>ПОЛЬЗОВАТЕЛИ</span> </div></Link>
                <Link to="/lilshop/mailing" style={active === 'Mailing' ? activeItemStyle : inactiveItemStyle}><div className="menu_close_icons"><MailOutlinedIcon   /> <span>РАССЫЛКИ</span> </div></Link>
                <Link to="/lilshop/promotionallinks" style={active === 'Promotional links' ? activeItemStyle : inactiveItemStyle}><div className="menu_close_icons"><InsertLinkOutlinedIcon   /> <span>РЕКЛАМНЫЕ ССЫЛКИ</span> </div></Link>
                <Link to="/lilshop/constructor" style={active === 'Constructor' ? activeItemStyle : inactiveItemStyle}><div className="menu_close_icons"><SettingsOutlinedIcon   /> <span>КОНСТРУКТОР</span> </div></Link>
                <Link to="/lilshop/payments" style={active === 'Payments' ? activeItemStyle : inactiveItemStyle}><div className="menu_close_icons"><StoreOutlinedIcon   /><span>ОПЛАТА</span> </div></Link>
                <i className="far fa-arrow-alt-circle-right"  onClick={openMenu}></i>
            </Stack>
    }

    
}
