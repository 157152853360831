import Header from "../Header";
import Menu from "../Menu";
import { Stack } from "@mui/material";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { getDataFromJsonList } from "../../functions/getDataFromJsonList";
import { config } from "../../config";
import Table from "../Table/Table";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import OrderAction from "./Orders/OrderAction";
import getCookie from "../../functions/getCookie";
import { useHistory } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LockOpenIcon from "@mui/icons-material/LockOpen";




export default function Sells() {
    const [orders, setOrders] = useState([])
    const [page, setPage] = useState(1)
    const [pages, setPages] = useState(0)
    const [limit, setLimit] = useState(10)
    const [search, setSearch] = useState('')
    const [id, setId] = useState(0)
    const [name, setName] = useState('')
    const history = useHistory();
    const [sorts, setSorts] = useState([
        { title: 'Название', name: "", type: null },
        { title: 'Категория', name: "", type: null },
        { title: 'Колл-во', name: "", type: null },
        { title: 'Сумма', name: "", type: null },
    ])

    const tableSetting = [
        [
            {
                'title': 'Название',
                'width': '30%',
                'data': ["Текст","Текст","Текст"],
                'styled': false,
                'id': [1,2,3],
            },
            {
                'title': 'Категория',
                'width': '30%',
                'data': ['Текст','Текст','Текст'],
                'styled': false,
            },
            {
                'title': 'Колл-во',
                'width': '20%',
                'data': [100,100,100],
                'styled': false,
            },
            {
                'title': 'Сумма',
                'width': '20%',
                'data': ['100','100','100',],
                'styled': false,
            },
        ],
        {
            'page': {
                'get': page,
                'set': setPage
            },
            'limit': {
                'get': limit,
                'set': setLimit
            },
            'pages': pages,
            'search': {
                'get': search,
                'set': setSearch
            },
            'filter': {
                'ifFiltered': false
            },
            'sort': {
                'get': sorts,
                'set': setSorts
            }
        }
    ]

    useEffect(() => {
        const sort = sorts.find((sortItem) => sortItem.type != null)

        fetch(`${config.host}/lilshop/refills/getSome`, {
            method: 'Post', // или 'PUT'
            headers: {
                'accept': 'text/plain',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'token': localStorage.getItem('token')
            },
            body: JSON.stringify({
                'limit': limit,
                'page': page,
                'search': search,
                'sort': sort ? sort.name : 'datetime',
                'sort_type': sort ? sort.type : 'desc'
            })
        }).then((response) => {
            if (response.status === 200) {
                response.json().then((data) => {
                    setOrders(data.data)
                    if (pages !== data.pages) {
                        setPages(data.pages)
                    }
                })
            }
        })

    }, [limit, page, search, sorts])

    useEffect(() => {
        if (page !== 1) {
            setPage(1)
        }
    }, [pages])

    useEffect(() => {

        if (id !== 0) {
            fetch(`${config.host}/lilshop/refills/getMaterial`, {
                method: 'Post', // или 'PUT'
                headers: {
                    'accept': 'text/plain',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'token': localStorage.getItem('token')
                },
                body: JSON.stringify({
                    'id': id
                }),
            }).then((response) => {
                if (response.status === 200) {
                    response.text().then((data) => {
                        const element = document.createElement("a");
                        const file = new Blob([data], { type: 'text/plain' });
                        element.href = URL.createObjectURL(file);
                        element.download = `${name}.txt`;
                        document.body.appendChild(element); // Required for this to work in FireFox
                        element.click();
                        setId(0)
                        setName('')
                    })
                }
                else if (response.status === 401) {
                    history.push('/lilshop/authentication', { from: "/lilshop/refills" })
                }
            })
        }

    }, [id])

    return <Stack direction={'row'}>
        <Menu active={'Sells'} />
        <Stack direction={'column'} width={'100%'}>
            <Header />
            <Stack className="main-style-page">
                <Stack className="inner-in-main-page">
                    <div className="upper-panel-in-inner">
                        <div className="about-page">Продажи</div>
                    </div>
                    <div className="split"></div>
                    <Table setting={tableSetting} />
                </Stack>

            </Stack>
        </Stack>

    </Stack>
}
