export const config = {
    "host": "https://test.api.liladminpanel.space",
    // "host": "https://api.liladminpanel.space",
    // "host": "http://localhost:5001"
}
export const quillModules = {
    toolbar: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['link', 'code'],

        // [{'header': 1}, {'header': 2}],               // custom button values
        // [{'list': 'ordered'}, {'list': 'bullet'}],
        // [{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript
        // [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
        // [{'direction': 'rtl'}],                         // text direction
        //
        // [{'size': ['small', false, 'large', 'huge']}],  // custom dropdown
        // [{'header': [1, 2, 3, 4, 5, 6, false]}],

        // [{'color': []}, {'background': []}],          // dropdown with defaults from theme
        // [{'font': []}],
        // [{'align': []}],
        // ['link', 'image', 'video'],
        // ['clean']                                         // remove formatting button
    ],
    imageResize: {
        // parchment: Quill.import('parchment'),
        modules: [ 'Resize', 'DisplaySize' ]
    }
}
