import { TextField } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import { config } from "../../config";
import { useHistory } from "react-router-dom";
import TelegramLoginButton from 'react-telegram-login';

const textFieldStyle = {
    minWidth: 300,
    width: '60%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '10px'
}

const saveButtonStyle = {
    color: 'white',
    backgroundColor: 'rgb(45, 3, 59)',
    ':hover': {
        backgroundColor: 'rgba(45,3,59,0.75)',
    },
    textTransform: 'none',
    fontSize: 20,
    minWidth: 300,
    width: '60%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '10px',
    marginBottom: '10px',
    height: '40px',
}


export default function Authentication() {
    const [login, setLogin] = useState('')
    const [pass, setPass] = useState('')
    const history = useHistory();

    // const handleSave = () => {
    //     fetch(`${config.host}/lilshop/auth`, {
    //         method: 'Post', // или 'PUT'
    //         headers: {
    //             'accept': 'text/plain',
    //             'Content-Type': 'application/json',
    //             'Access-Control-Allow-Origin': '*',
    //         },
    //         body: JSON.stringify({
    //             'login': login,
    //             'pass': pass,
    //         })
    //     }).then((response) => {
    //         if (response.status === 200) {
    //             response.json().then((data) => {
    //                 localStorage.setItem('login', login)
    //                 localStorage.setItem('token', data.token)
    //                 history.push('/lilshop/analytics', {from: "/lilshop/authentication"})
    //             })
    //         }
    //     })
    //
    // }

    const checkTelegramAuth = (id) => {
        fetch(`${config.host}/lilshop/telegramAuthCheck`, {
            method: 'Post', // или 'PUT'
            headers: {
                'accept': 'text/plain',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            body: JSON.stringify({
                'id': id,
            })
        }).then((response) => {
            if (response.status === 200) {
                response.json().then((data) => {
                    if (data.status === 'wait') {
                        setTimeout(checkTelegramAuth, 5000, id)
                    }
                    else if (data.status === 'accept') {
                        localStorage.setItem('token', data.token)
                        history.push('/lilshop/analytics', { from: "/lilshop/authentication" })
                    }
                })
            }
        })
    }
    const handleTelegramResponse = response => {
        const id = response.id
        fetch(`${config.host}/lilshop/telegramAuth`, {
            method: 'Post', // или 'PUT'
            headers: {
                'accept': 'text/plain',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            body: JSON.stringify({
                'id': response.id,
                'first_name': response.first_name,
                'username': response.username,
            })
        }).then((response) => {
            if (response.status === 200) {
                response.json().then((data) => {
                    if (data.status === 'wait') {
                        setTimeout(checkTelegramAuth, 5000, id)
                    }
                })
            }
        })
        // alert('Logged in as ' + response.first_name + ' ' + response.last_name + ' (' + response.id + (response.username ? ', @' + response.username : '') + ')')
    };

    return <div>
        {/*<TextField size="small" sx={textFieldStyle} label={'Логин'} onChange={(e) => setLogin(e.target.value)}/>*/}
        {/*<TextField type="password" size="small" sx={textFieldStyle} label={'Пароль'}*/}
        {/*           onChange={(e) => setPass(e.target.value)}/>*/}
        {/*<Button sx={saveButtonStyle} onClick={handleSave}>Сохранить</Button>*/}
        <TelegramLoginButton dataOnauth={handleTelegramResponse} botName="test_lil_admin_bot" />
    </div>
}
